import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { AuthService } from "@pages/auth/services/auth.service";
import { ErrorComponent } from "../error.component";

@Component({
  selector: "poocho-access-denied",
  standalone: true,
  imports: [ErrorComponent],
  templateUrl: "./access-denied.component.html",
  styleUrl: "./access-denied.component.scss",
})
export class AccessDeniedComponent {
  constructor(
    private autService: AuthService,
    private router: Router
  ) {}
  goBackHome(): void {
    this.autService.logout();
    this.router.navigate(["/home"]);
  }
}
