import { CommonModule } from "@angular/common";
import { Component, HostListener } from "@angular/core";
import { RouterOutlet } from "@angular/router";
import { MixpanelService } from "@core/services/mixpanel.service";
import { FooterComponent, HeaderComponent } from "@shared/components";

@Component({
  selector: "poocho-layout3",
  standalone: true,
  imports: [RouterOutlet, CommonModule],
  templateUrl: "./layout3.component.html",
  styleUrl: "./layout3.component.scss",
})
export class Layout3Component {
  isScrolled = false;

  constructor(private mixpanelService: MixpanelService) {}

  @HostListener("window:scroll", [])
  onScroll() {
    this.isScrolled = window.scrollY > 0;
  }

  scrollToTop() {
    this.mixpanelService.track("scroll_top_click");
    window.scrollTo({ top: 0, behavior: "smooth" });
  }
}
